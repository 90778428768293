@use './colors' as *;
@use './spacing' as *;
@use './devices' as *;
@use './typography' as *;

/* ===========
 Form elements
=========== */

input,
textarea {
  color: $text-dark;

  &:focus-visible {
    outline: 3px solid $primary;
  }
}

/* ===========
 Text elements (using mixins to separate styles from semantic html)
=========== */

:root {
  --body-size: #{$body-font-size-mobile};
  --empty-line: calc(var(--body-size) * #{$body-line-height-factor});

  font-size: var(--body-size);
  color: $text-dark;

  @include mouse-device {
    --body-size: #{$body-font-size-laptop};
  }
}

body {
  @include body;
}

h3 {
  @include h3;
}

h2 {
  @include h2;
}

h1 {
  @include h1;
}

.overline {
  @include overline;
}

.caption {
  @include caption;
}
