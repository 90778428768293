@use 'styles/colors' as *;
@use 'styles/spacing' as *;
@use 'styles/utils' as *;
@use 'styles/devices' as *;

.body {
  @include horizontal-page-padding;
  background-color: $primary;
  color: $text-light;
  padding-top: $xl;

  @include tablet-or-mouse-device {
    padding-top: $xxl;
  }
}

.viewport {
  overflow-x: hidden;
}

.contentContainer {
  margin: 0 auto;
  width: fit-content;
}
